import {
  computed,
  watch
} from "vue"
import { useRouter } from "vue-router"

import {
  t,
  plural
} from "@lingui/macro"

import Navigation from "./Navigation"

import assets from "~/assets"
import http from "~/http"
import { useStore } from "~/store"

import Auth from "~/pages/Auth"
import PaymentModal from "~/components/PaymentModal"

export default {
  async setup() {
    const store = useStore()
    const router = useRouter()

    const accounts = computed(() => store.accounts)
    const Modal = computed(() => store.modal)

    const {
      body
    } = document

    watch(() => Modal.value, next => {
      body.classList[
        next === null
          ? "remove"
          : "add"
      ]("modal-open")
    })

    try {
      const [
        { data: accounts },
        { data: wallets }
      ] = await Promise.all([
        http.get("accounts"),
        http.get("wallets")
      ])

      store.accounts = accounts
      store.wallets = wallets
    } finally {
      const isAuth = /^\/auth/.test(location.pathname)

      if (accounts.value) {} else {
        return router.push(
          isAuth
            ? location.pathname + location.search
            : "/auth/login"
        )
      }
    }

    return {
      store,
      accounts,
      Modal
    }
  },
  render() {
    const {
      store,
      accounts,
      Modal
    } = this

    return (
      <Fragment>
        {accounts ? (
          <Fragment>
            <div class="w-100 border-bottom">
              <div class="container px-0 py-4">
                <div class="d-flex justify-content-between">
                  <RouterLink to="/">
                    {assets.logotype && (
                      <img
                        class="px-xl-0 ps-4"
                        src={assets.logotype}
                        style={`
                          margin-top: -1px;
                          height: 40px;
                        `}
                      />
                    )}
                  </RouterLink>
                  <button
                    class="btn-link btn px-0 text-decoration-none me-4"
                    onClick={() => {
                      delete localStorage.accessToken
                      location.reload()
                    }}
                  >
                    {t`Log Out`}
                  </button>
                </div>
              </div>
            </div>
            <div class="container px-0 py-4">
              <div class="row">
                <div class="col">
                  <Navigation/>
                </div>
                <div class="col-9 r-side">
                  <RouterView/>
                </div>
              </div>
            </div>
            {Modal && <Modal/>}
          </Fragment>
        ) : (
          <RouterView/>
        )}
      </Fragment>
    )
  }
}
