import {
  createRouter,
  createWebHistory
} from "vue-router"

import Home from "./pages/Home"
import Equipment from "./pages/Equipment"
import Payments from "./pages/Payments"
import Statistics from "./pages/Statistics"
import Support from "./pages/Support"

import Auth from "./pages/Auth"
import LoginView from "./pages/Auth/LoginView"
import RegisterView from "./pages/Auth/RegisterView"
import ResetView from "./pages/Auth/ResetView"

export default createRouter({
  routes: [
    { path: "/", component: Home },
    { path: "/equipment", component: Equipment },
    { path: "/payments", component: Payments },
    { path: "/statistics", component: Statistics },
    { path: "/support", component: Support },

    //

    {
      path: "/auth",
      component: Auth,
      children: [
        { path: "login", component: LoginView },
        { path: "register", component: RegisterView },
        { path: "reset", component: ResetView },
        { path: "", redirect: "login" }
      ]
    },

    //

    {
      path: "/referral/:code", redirect: (to) => ({ path: "/auth/register", query: { referral: to.params?.code } })
    }
  ],
  history:
    createWebHistory(),
  linkActiveClass: "active"
})
