import {
  computed,
  toRefs
} from "vue"

import {
  t,
  plural
} from "@lingui/macro"

import {
  useEquipmentStore
} from "~/store"

import Badge from "./Badge"
import { useForm, useField } from "vee-validate"

export default {
  props: [
    "id",
    "status",
    "isEditing",
    "onClose",
    "ip"
  ],
  setup(props) {
    const equipmentStore = useEquipmentStore()

    const {
      id,
      isEditing,
      ip
    } = props

    const {
      errors,
      handleSubmit,
      isSubmitting
    } = useForm({
      initialValues: {
        ip: ip || null
      }
    })

    const { value: ipRef } = useField("ip")

    const onSubmit = handleSubmit(
      async (values, { setErrors }) => {
        try {
          if (isEditing)
            await equipmentStore.update(id, values)
          else
            await equipmentStore.create(values)

          props.onClose()
        }
        catch (error) {
          setErrors([
            error
              .response
              .data
          ])
        }
      }
    )

    async function handleDelete(evt) {
      evt.preventDefault()
      await equipmentStore.delete(id)
      props.onClose()
    }

    const isDisabled = computed(
      () => ! ipRef.value || isSubmitting.value || ipRef.value === ip
    )

    return {
      errors,
      handleDelete,
      ipRef,
      isDisabled,
      onSubmit
    }
  },
  render() {
    const {
      errors,
      handleDelete,
      ipRef,
      isDisabled,
      onSubmit
    } = this

    const {
      id,
      ip,
      isEditing,
      status
    } = this.$props

    return (
      <Modal
        onClose={() => {
          this.onClose()
        }}
      >
        <div class="modal-body">
          <form onSubmit={onSubmit} id="equipment">
            {isEditing && (
              <Fragment>
                <div class="mb-2">
                  <div class="row">
                    <div class="col-2">
                      <label>
                        {t`ID`}:
                      </label>
                    </div>
                    <div class="col text-end">
                      {id}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <label>
                        {t`Status`}:
                      </label>
                    </div>
                    <div class="col text-end">
                      <Badge value={status} />
                    </div>
                  </div>
                </div>
                <hr class="mt-0" />
              </Fragment>
            )}
            <label>
              {t`IP Address`}:
            </label>
            <input
              v-model={this.ipRef}
              placeholder=""
              type="text"
            />
            {errors[0] && (
              <div class="mt-2 text-danger">
                {errors[0]}
              </div>
            )}
          </form>
        </div>
        <div class="modal-footer">
          {isEditing && (
            <a
              href="#"
              onClick={handleDelete}
              class="link-danger me-auto"
            >
              {t`Delete`}
            </a>
          )}
          <button
            class="btn btn-secondary"
            onClick={() => {
              this.onClose()
            }}
          >
            {t`Close`}
          </button>
          <button class="btn btn-primary" disabled={isDisabled} form="equipment">
            {isEditing
              ? t`Save`
              : t`Add`}
          </button>
        </div>
      </Modal>
    )
  }
}
